import { signIn } from 'next-auth/react'
import React from 'react'

export default function Login() {

  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    signIn('fusionauth');
  }, [])

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <p className={styles.description}>
          <code className={styles.code}>Loading...</code>  
        </p>
      </main>
    </div>
  )
}